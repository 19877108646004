import React, { useEffect, useState } from "react";
import abcoLogo from "../../assets/images/abco.png";
import { FooterContainer } from "./Footer.style";
import Icons from "../Icons/Icons";
import { IIcon } from "../../interfaces";
import { Link } from "react-router-dom";
import { Section } from "..";
import { sanityFetchAllByType } from "../../utils/globalFunctions";
import warehouseNSW from "../../assets/images/footer/warehouse-nsw.png";
import warehousePerth from "../../assets/images/footer/warehouse-perth.png";
import warehouseMelbourne from "../../assets/images/footer/warehouse-melbourne.png";
import warehouseBrisbane from "../../assets/images/footer/warehouse-brisbane.png";
import warehouseAdelaide from "../../assets/images/footer/warehouse-adelaide.png";

const Footer = () => {
  const [legalPolicies, setLegalPolicies] = useState<any>([]);
  useEffect(() => {
    let fetchRes = sanityFetchAllByType("legalPolicies");

    fetchRes.then((res) => {
      setLegalPolicies(res);
    });
  }, []);
  return (
    <FooterContainer id="footer">
      <Section className="footer-section">
        <div className="content-container">
          <div className="left">
            <div className="left-content">
              <div className="left-content-item">
                <img src={abcoLogo} alt="Abco Logo" className="abco-logo" />
                <p className="abco-description">
                  <b>
                    Australia’s most trusted commercial cleaning products and
                    equipment supplier
                  </b>
                </p>
              </div>
              <div className="left-content-item">
                <h3>Contact</h3>
                <div className="left-content-item-info phone">
                  <a href="tel:1800177399">
                    <Icons type={IIcon.phone} />{" "}
                    <span className="info">1800 177 399</span>
                  </a>
                </div>
                <div className="left-content-item-info mail">
                  <a
                    href="mailto: sales@abcopro.com.au"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icons type={IIcon.mail} />{" "}
                    <span className="info">sales@abcopro.com.au</span>
                  </a>
                </div>
                <div className="left-content-item-info mail">
                  <Link to="/about-us/book-anappointment">
                    <Icons type={IIcon.cursor} />{" "}
                    <span className="info">Book Appointment</span>
                  </Link>
                </div>
                <div className="left-content-item-info time">
                  <div style={{ display: "flex" }}>
                    <Icons className="time-icon" type={IIcon.clock} />{" "}
                    <span className="info">Mon - Fri: 7:00am - 2:00am</span>
                  </div>
                </div>
              </div>
              <div className="left-content-item">
                <h3>Legal</h3>
                <ul>
                  {legalPolicies.length !== 0 &&
                    legalPolicies.map((item: any, key: number) => (
                      <li key={key}>
                        <Link to={`/legal-policies/${item.slug.current}`}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="right-content" id="footer-location">
              <h3>Locations</h3>
              <p className="description-location">
                All visits to showroom, require a prior booking.
                <br /> To book an appointment please click here{" "}
                <Link to="/about-us/book-anappointment">HERE</Link>.
              </p>
              <div className="right-content-item">
                <div className="address-container">
                  <h4>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/y2HAUKHTo9QNnLT69"
                    >
                      Adelaide
                    </a>
                  </h4>
                  <p className="address">
                    507 Cross Keys Road,
                    <br /> Cavan SA 5094
                  </p>
                </div>
                <div className="image-placeholder">
                  <img src={warehouseAdelaide} alt="Adelaide Warehouse" />
                </div>
              </div>
              <div className="right-content-item">
                <div className="address-container">
                  <h4>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/E7iWW6p7AszrQ3WW6"
                    >
                      Brisbane
                    </a>
                  </h4>
                  <p className="address">
                    2-14 Prospect Place, <br />
                    Park Ridge QLD 4125 <br />
                  </p>
                </div>
                <div className="image-placeholder">
                  <img src={warehouseBrisbane} alt="Brisbane Warehouse" />
                </div>
              </div>
              <div className="right-content-item">
                <div className="address-container">
                  <h4>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/e1jpANYWGGjpnkit9"
                    >
                      Melbourne
                    </a>
                  </h4>
                  <p className="address">
                    13 Prosperity Street,
                    <br /> Truganina VIC 3029
                  </p>
                </div>
                <div className="image-placeholder">
                  <img src={warehouseMelbourne} alt="Melbourne Warehouse" />
                </div>
              </div>
              <div className="right-content-item">
                <div className="address-container">
                  <h4>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/FM9hyRJ7Q2tfaYXSA"
                    >
                      Perth
                    </a>
                  </h4>
                  <p className="address">
                    44 John Street,
                    <br />
                    Bentley WA 6102
                  </p>
                </div>
                <div className="image-placeholder">
                  <img src={warehousePerth} alt="Perth Warehouse" />
                </div>
              </div>
              <div className="right-content-item">
                <div className="address-container">
                  <h4>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://goo.gl/maps/j6DiQkk3zir6DtMTA"
                    >
                      Sydney
                    </a>
                  </h4>
                  <p className="address">
                    3B 34 Yarrunga Street, <br />
                    Prestons NSW 2170
                  </p>
                </div>
                <div className="image-placeholder">
                  <img src={warehouseNSW} alt="NSW Warehouse" />
                </div>
              </div>
            </div>
          </div>
          <div className="right" style={{ paddingLeft: 24 }}>
            <div className="right-content" id="footer-location">
              <h3>New Zealand</h3>
              <p className="description-location">
                Abco expands operations into New Zealand with acquisition of
                Proquip.
                <br /> To view the locations of our New Zealand branches, please
                click{" "}
                <Link
                  to="#"
                  onClick={() =>
                    window.open(
                      "https://www.proquipnz.co.nz/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  HERE
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </Section>
      <div className="footer-bottom">
        <span>Copyright 2022 Abco Products Pty Ltd</span>
        <p>
          Abco acknowledges the Traditional Owners of the land where we work and
          live. We pay our respects to Elder's past, present and emerging. We
          celebrate the stories, culture and traditions of Aboriginal and Torres
          Strait Islanders of all communities who also work and live on this
          land.
        </p>
      </div>
    </FooterContainer>
  );
};

export default Footer;
