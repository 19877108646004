import React, { Suspense, useEffect, useState } from "react";
import Theme from "./Theme/Theme";
import GlobalStyle from "./Theme/globalStyle";
import { Route, Switch } from "react-router-dom";
import { Footer, ScrollToTop, Navbar } from "./components";
// import routes from "./Routes/Routes";
import { PageNotFound } from "./pages";
import ContextProvider from "./Context/ContextProvider";
import LoaderComponent from "./components/Loader/LoaderComponent";
import Routes from "./Routes/Routes";
import { Seo } from "./components";
import {
  getParams,
  isEmpty,
  sanitySiteSettings,
  urlFor,
} from "./utils/globalFunctions";
import FormProvider from "./Context/FormContext";
import { useLocation } from "react-router-dom";
import { useToken } from "./utils/customHooks";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
const WithLayout = (Component: any) => () =>
  (
    <div>
      <Navbar />
      <Component />
      <Footer />
    </div>
  );

Bugsnag.start({
  apiKey: "6523f4075df96ccbd15761150a19f524",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production"],
});

//@ts-expect-error
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const App = () => {
  const location = useLocation();
  const { setToken } = useToken();
  const [siteSettings, setSiteSettings] = useState({
    title: "Abcopro | The Power To Clean",
    description: "Test Description",
    keyword: "Test Keyword",
    image: null,
  });
  useEffect(() => {
    sanitySiteSettings().then((data) => {
      setSiteSettings(data[0]);
    });
  }, []);

  useEffect(() => {
    let paramList = getParams(window.location.href);

    const allowed = ["utm_campaign", "gclid", "campaign", "adaccount"];

    const filtered = Object.keys(paramList)
      .filter((key) => allowed.includes(key))
      .reduce((obj: any, key) => {
        obj[key] = paramList[key];
        return obj;
      }, {});

    if (!isEmpty(filtered)) {
      setToken(paramList);
    } else {
      console.log("Not campaign");
    }
  }, [location, setToken]);

  return (
    <ErrorBoundary>
      <FormProvider>
        <ContextProvider>
          <Seo
            title={siteSettings.title}
            description={siteSettings.description}
            keyword={siteSettings.keyword}
            image={siteSettings.image !== null && urlFor(siteSettings.image)}
          />
          <Theme>
            <Suspense fallback={<LoaderComponent />}>
              <GlobalStyle />
              {/* <SmoothScroll> */}
              {/* <TopNavbar /> */}

              <ScrollToTop>
                <Switch>
                  {Routes.map((route, key) => (
                    <Route
                      key={key}
                      exact
                      path={route.path}
                      component={
                        route.path !== "/campaign/:slug" &&
                        route.path !== "/promotions/:slug"
                          ? WithLayout(route.component)
                          : route.component
                      }
                    />
                  ))}
                  <Route component={PageNotFound} />
                </Switch>
              </ScrollToTop>
              {/* </SmoothScroll> */}
            </Suspense>
          </Theme>
        </ContextProvider>
      </FormProvider>
    </ErrorBoundary>
  );
};

export default App;
